/*-------------------
     Placeholder
--------------------*/

.ui.placeholder.segment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  max-width: initial;
  -webkit-animation: none;
  animation: none;
  overflow: visible;
  padding: 1em 1em;
  min-height: 18rem;
  background: #f9fafb;
  border-color: rgba(34, 36, 38, 0.15);
  -webkit-box-shadow: 0px 2px 25px 0 rgba(34, 36, 38, 0.05) inset;
  box-shadow: 0px 2px 25px 0 rgba(34, 36, 38, 0.05) inset;
}
.ui.placeholder.segment .button,
.ui.placeholder.segment textarea {
  display: block;
}
.ui.placeholder.segment .field,
.ui.placeholder.segment textarea,
.ui.placeholder.segment > .ui.input,
.ui.placeholder.segment .button {
  max-width: 15rem;
  margin-left: auto;
  margin-right: auto;
}
.ui.placeholder.segment .column .button,
.ui.placeholder.segment .column .field,
.ui.placeholder.segment .column textarea,
.ui.placeholder.segment .column > .ui.input {
  max-width: 15rem;
  margin-left: auto;
  margin-right: auto;
}
.ui.placeholder.segment > .inline {
  -ms-flex-item-align: center;
  align-self: center;
}
.ui.placeholder.segment > .inline > .button {
  display: inline-block;
  width: auto;
  margin: 0px 0.35714286rem 0px 0px;
}
.ui.placeholder.segment > .inline > .button:last-child {
  margin-right: 0px;
}
