@import "./component_styles/dropdown.css";
/* temporary styles until we can implement a design system.  
!important is required to override native Semantic styling.  */
/* Styles for support worker cards */
@media only screen and (max-width: 650px) {
  .center {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/* Styles for Buttons */
.tappon-button {
  color: white !important;
  background-color: #66c7c9 !important;
}

.tappon-button:hover {
  background-color: #3d8187 !important;
}

/* Styles for mobile side-bar */
.tappon-sidebar {
  background: #3d8187 !important;
}

.ui.card a .header {
  color: #000;
  font-weight: 700;
  font-size: 1.28571429em;
  margin-top: -0.21425em;
  line-height: 1.28571429em;
  font-size: 18px;
  font-family: "Lato";
}

/* Menu styles */

.ui.sidebar.menu .item {
  font-size: 15pt !important;
}

.ui.form .field.inline-field {
  display: inline-block;
  width: 49%;
  margin: 0 2px 18px 0;
}

.ui.form .field .text {
  line-height: 1.2;
}

.ui.form .field.inline-field .text {
  white-space: nowrap;
}

@media (min-width: 692px) and (max-width: 974px) {
  .ui.form .field.inline-field {
    width: 100%;
  }
}
/* Starting to implement new tailwind system */

html {
  box-sizing: border-box;
  font-size: 14px;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}


a {
  text-decoration: none;
  color: #4183c4;
}

/* Custom classes to extend tailwind */

.min-w-56 {
  min-width: 14rem;
}

.opacity-85 {
  opacity: 0.85;
}

/* Styling the react-dates Datepicker input 

  Mimicking Semantic for now
*/

.date-picker .DateInput {
  width: 170px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.date-picker .DateInput input {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0.5em 1em;
  font-size: 1rem;
  border-radius: 0.28571429rem;
  font-weight: normal;
}

.date-picker.error .DateInput input {
  border: 1px solid #fed7d7;
  background-color: #fff5f5;
}

.date-picker.error .DateInput input::placeholder {
  color: #c53030;
}
